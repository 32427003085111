import React from 'react'
import styled from 'styled-components'
import tw from "tailwind.macro"
import {SRow} from '../row/row'

const Container = (props) => {
  const {children} = props
  
  const SContainer = styled.div`
    ${SRow}:nth-child(n+2) {
      ${tw`pt-6`}
    }
  `
  return (<SContainer className="container">{children}</SContainer>)
}

export default Container