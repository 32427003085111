import React from 'react'
import tw from "tailwind.macro"
import styled, {css} from 'styled-components'
import {theme} from '../../../../tailwind.config'

const Button = (props) => {
  const {color, size, children, tag} = props

  let sBase = css`
    border-radius: 4px;
    color: white;
    text-transform: uppercase;
    transition: 100ms;
    font-weight: 400;
    ${tw`
      inline-block
    `}
    i {
      margin-left: 20px;
    }
  `
  const sSize = (size) => {
    switch(size) {
      case 'large':
        return css`
          padding: 20px 30px;
          ${tw` text-large`}
          &:hover {
            transform: translate(0,-2px);
            box-shadow: 0 9px 13px -6px rgba(0,0,0,.23)
          }
        `
      case 'small':
        return css`
          padding: 0px 0px;
          ${tw` text-sm`}
          &:hover {
            transform: translate(0,-2px);
            box-shadow: 0 7px 10px -4px rgba(0,0,0,.20)
          }
        `
      case 'medium':
        return css`
          padding: 10px 20px;
          ${tw` text-sm`}
          > i {
            ${tw` ml-3`}
          }
          &:hover {
            transform: translate(0,-2px);
            box-shadow: 0 7px 10px -4px rgba(0,0,0,.23)
          }
        `
      default:
        return css`
          padding: 3px;
        `
    }
  }

  const sColor = (color) => {
    switch(color) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary}
          color: white !important;
          &:hover {
            color: white;
          }
        `
      case 'primary-border':
        return `
          background-color: white;
          border: 1px solid ${theme.colors.primary};
          color: ${theme.colors.primary};
          &:hover {
            color: ${theme.colors.primary};
          }
        `
      case 'green':
        return `
          background-color: ${theme.colors.green}
          color: white;
          &:hover {
            color: white;
          }
        `
      case 'white':
        return `
          background-color: white;
          color: ${theme.colors.primary};
          &:hover {
            color: ${theme.colors.primary};
          }
        `
      default:
        return `
          background-color: ${theme.colors.primary}
        `
    }
  }

  let SButton = null;
  if (tag === 'button') {
    SButton = styled.button`
      ${sBase}
      ${sSize(size)}
      ${sColor(color)}
    `
  } else {
    SButton = styled.a`
      ${sBase}
      ${sSize(size)}
      ${sColor(color)}
    `
  }


  // TODO: delete props colors / size 
  return (<SButton {...props} >{children}</SButton>)
}

export default Button