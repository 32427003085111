import React from "react"
import classnames from "classnames"
import styled from "styled-components"
import {theme} from '../../../../tailwind.config'
import ImageOverlaySvg from '../../../images/hero-bg.svg'

const Section = ({ children, className, padding, hero, color }) => {  

  let sCss = ``
  let twStr = ``
  twStr += (padding) ? ' pt-16 pb-16' : ''
  
  if (color === 'alt') {
    sCss += ` background: ${theme.colors.gray.lighter}`
  } else if (color === 'dark') {
      sCss += ` background: ${theme.colors.dark}
        color: white;
      `
  } else if (color === 'primary') {
    sCss += ` 
      position: relative;
      overflow: hidden;
      background: ${theme.colors.primary}; 
      color: white;
      &:before {
        position: absolute;
        top: -500px;
        right: 0;
        display: block;
        content: '';
        width: 1400px;
        height: 990px;
        opacity: 0.17;
        transform: rotate(-18deg);
        background-image: linear-gradient(-19deg, #21D4FD 0%, #B721FF 100%);
        border-radius: 90px;
      }
    `
  }

  let SOverlay = styled.div``;
  if (hero) {
    SOverlay = styled.div`
        background-image: url(${ImageOverlaySvg});
        content: '';
        position: absolute;
        z-index: 1;
        display: block;
        left: 35%;
        top: 0px;
        width: 4000px;
        height: 2690px;
        background-repeat: no-repeat;
        text-align: right;
        z-index: 0;
        @media(max-width: ${theme.screens.md.max}) {
          left: 65%;
        }
    `
  }

  const SSection = styled.section`
    ${sCss}  
  `
  const classes = (className) ? className + ' ' + twStr : twStr
  return (
  <SSection className={classnames({[classes]: true})}>
<SOverlay/>
    {children}

  </SSection>)
}


export default Section
