module.exports = {
  theme: {
    screens: {
      'tablet': '640px',
      'sm': {'min': '640px', 'max': '767px'},
      'md': {'min': '768px', 'max': '1023px'},
      'lg': {'min': '1024px', 'max': '1279px'},
      'xl': {'min': '1280px'},
      'maxsm': {'max': '640px'},
    },
    colors: {
      primary:'#6267ED',
      purple:'#6267ED',
      gray: {
        lighter: '#F3F5F9'
      },
      text:'#3C4761',
      green: '#33D8B0',
      blue: '#62B2ED',
      pink: '#E200FF',
      dark: '#20202D',
      red: '#E87272',
      aubergine: '#5B57A8',
    },
  }
}