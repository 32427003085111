import React from 'react'
import tw from "tailwind.macro"
import { Link as LinkBase } from "gatsby"
import styled from 'styled-components'
import {theme} from '../../../../tailwind.config'

const Link = (props) => {
  let { color, arrow, children, underline } = props
  let SlinkTag = null;

  // Use gatsdby Link when to attribute is defined
  if (props.to) {
    SlinkTag = LinkBase
  } else {
    SlinkTag = 'a'
  }

  color = (color && color === 'light') ? 'white' : theme.colors.primary
  let SArrow = styled.span`
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    transition: 75ms linear;
  `
  let SLink  = styled(SlinkTag)`
    ${tw`text-base`}
    padding-bottom: 2px;
    position: relative;
    color: ${color};
    
    &:hover {
      color: ${color}
    }

  `
  
  let SLinkContent = styled.span`
    display: inherit;
    position: relative;
    &:after {
      content: '';
      border-bottom: 1px solid ${color};
      width: 100%;
      left: 0;
      display: block;
      position: absolute;
      display: ${ (underline === false) ? 'none' : 'block' };
    }
  `;

  // Arrows
  let SArrowLeftElt = null;
  let SArrowRightElt = null;
  
  if (arrow === 'right') {
    SLink = styled(SLink)`
      &:hover ${SArrow} {
        right: -30px;
      }
    `
    let SArrowRight = styled(SArrow)`
      right: -25px;
    `
    SArrowRightElt = () => (<SArrowRight>⭢</SArrowRight>);
  } else if (arrow === 'left') {
    SLink = styled(SLink)`
      &:hover ${SArrow} {
        left: -5px;
      }
    `
    let SArrowLeft = styled(SArrow)`
      left: 0px;
    `
    SLinkContent = styled(SLinkContent)`
      margin-left: 25px;
    `
    SArrowLeftElt = () => (<SArrowLeft>⭠</SArrowLeft>);
  }
  
  return (<SLink {...props}>{ (SArrowLeftElt && <SArrowLeftElt/>) }<SLinkContent>{children}</SLinkContent>{ (SArrowRightElt && <SArrowRightElt/>) }</SLink>)
}

export default Link