import React from 'react'
import { JSONLD, Generic, GenericCollection, Question } from 'react-structured-data'
import { Typography } from "components/ui"
import find from 'lodash/find'
import { faqs } from './faqConfig'
import styled from 'styled-components'
import {theme} from '../../../../tailwind.config'
import tw from "tailwind.macro"

const renderJsonLd = (faqList) => {

  return (
    <JSONLD>
      <Generic type="FAQPage" jsonldtype="FAQPage">
        <GenericCollection type="mainEntity">
        { faqList.map(({title, content}, index) => (
          <Question name={title}>
            <Generic type="acceptedAnswer" jsonldtype="Answer" schema={{ text: content}}></Generic>
          </Question>
        ) )}       
        </GenericCollection>
      </Generic>
    </JSONLD>
  )
}

// FAQ List / Container
const Faqs = (props) => {

  let { cols, ids } = props
  cols = (!cols) ? 2 : cols

  // Styles
  const SFaqs = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -40px;
    > div {
      padding-left: 40px;
      ${tw`mb-4`}
      width: ${(cols) ? (100/cols) : (100/3)}%;
    }
    @media(max-width: ${theme.screens.md.max}) {
      margin-left: 0px;
      > div {
        ${tw`pl-0 mb-4`}
        width: 100%;
      }
    }
  `
  const faqList = ids.map((id, index) => find(faqs, {id}))
  
  return (
    <>
      <SFaqs {...props} cols={cols}>
        { faqList.map((element, index) => (<Faq key={index} faq={element}/>) )}
      </SFaqs>
      { renderJsonLd(faqList) }
    </>
  )
}

// Individual FAQ Component
const Faq = (props) => {
  const {title, content} = props.faq
  return (
    <div>
      <Typography tag="h3">{title}</Typography>
      <p dangerouslySetInnerHTML={{ __html: content }}/>
    </div>
  )
}

export default Faqs