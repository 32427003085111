import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import {theme} from '../../../../tailwind.config'


export const Vignette = (props) => {
  const SVignette = styled.div`
    border-radius: 150px;
    width: 150px;
    height: 150px;
    padding: 50px;
    background: ${(props.color === 'alt') ? '#F3F5F9' : 'white'};
    display: block
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center

  `
  return(<><SVignette {...props}>{props.children}</SVignette><p className="mt-2 vignette-label">{props.label}</p></>)
}

export const VignetteContainer = (props) => {
  const SVignetteContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 1px;
    > * { transition: 200ms; }
    > div, > a { 
      display: flex;
      align-items: center;
      flex:1;
      flex-direction: column;
      justify-content: center;
    }
    > a {
      .vignette-label {
        transition: 100ms;
        margin-bottom: 0px;
        ${tw`text-text`};
        opacity: 0.7;
      }
      &:hover {
        .vignette-label {
          ${tw`text-text`} !important;
          opacity: 1;
        }
      }
    }

    @media(max-width: ${theme.screens.md.max}) {
      flex-wrap: wrap;
      > div, > a {
        ${tw`mt-6`}
      }
    }
  `

  return(<SVignetteContainer {...props}>{props.children}</SVignetteContainer>)
}






