import project from '../configs/project';

export const signupUrl = (params) => {
    if (!! params) {
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return `${project.signupUrl}?${queryString}`
    }

    return `${project.signupUrl}`
}

export const loginUrl = (params) => {
  return `${project.loginUrl}`
}

export const supportUrl = (params) => {
  return `${project.supportUrl}`
}
//export default { signupUrl };