import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

const SectionTitle = (props) => {  
  const SSectionTitle = styled.div`
    ${tw`mb-6`}
  `
  return <SSectionTitle {...props}>{props.children}</SSectionTitle>
}

export default SectionTitle
