import React from 'react'
import { Button, Typography } from "components/ui"
import { signupUrl } from "../../../utils/urlHelper"
import styled from 'styled-components'

const SignupButton = ({color, background, size, urlAttr, className, text}) => {
  urlAttr = (!urlAttr) ? {sb_source: "website"} : urlAttr;
  color = (!color) ? 'green' : color;
  size = (!size) ? 'large' : size;
  text = (text) ? text : 'Secure your backups';

  const SSubTitle = styled(Typography)`
    font-size: 0.90rem !important;
    line-height: 1.5rem;
    opacity: 0.7;
  `

  return (
    <>
      <Button color={color} size={size} href={signupUrl(urlAttr)} className={className}>
        { text }<i className="fa fa-arrow-right"></i>
      </Button>
      <SSubTitle tag="p" className="mt-2">
        First backup for FREE. No credit card required. <br/>Free 14-day trial. 90-day money-back guarantee.</SSubTitle>
    </>
  )
}

export default SignupButton
