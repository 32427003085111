import React from 'react'
import tw from "tailwind.macro"
import styled from 'styled-components'
import Image from "components/image"

const Testimonial = (props) => {
  const {avatar, name, role, content, position, className} = props;

  let SBox = styled.div`
    text-align: left;
    position: relative;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 45px 0 rgba(0,0,0,0.14);
    ${tw`p-8 text-sm text-text`}
  `
  const SAvatar = styled(Image)`
    border-radius: 100%;
    width: 45px;
    height: 45px;
  `
  let SWho = styled.div`
    ${tw`text-sm ml-3`}
    line-height: 1.2rem;
    text-align: left;
    > div:nth-child(2) {
      ${tw`text-xs opacity-75`}
    }
  `

  let SArrow = null;
  let SHead = null;
  let sPosition = null;
  if (position === 'bottom') {
    SArrow= styled.div`
      content: '';
      position: absolute;
      bottom: -7px;
      left: calc(50% - 7px);
      width: 0px; 
      height: 0px; 
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid white;
    `
    SHead = styled.div`
      order: 2;
      position: relative;
      bottom: -20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      ${tw`mb-4`}
    `
    sPosition = `
      display: flex;
      justify-content: center;
      flex-direction: column;
    `
    SWho = styled.div`
      line-height: 1.2rem;
      text-align: center;
      > div:nth-child(2) {
        ${tw`text-xs opacity-75`}
      }
@    `
  } else {
    SArrow= styled.div`
      content: '';
      position: absolute;
      top: -7px;
      left: 17px;
      width: 0px; 
      height: 0px; 
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid white;
    `
    SHead = styled.div`
      display: flex;
      align-items: flex-start;
      ${tw`mb-4`}
    `
  }

  const STestimonial  = styled.div`

    position: relative;
    max-width: 340px;
    ${sPosition}
  `
  
  return (
  <STestimonial {...className} {...props}>
    <SHead>
      <SAvatar filename={avatar} alt={`SimpleBackups testimonial by ${name}`}></SAvatar>
      <SWho>
        <div>{name}</div>
        <div>{role}</div>
      </SWho>
    </SHead>
    <SBox>
      {content}
      <SArrow/>
    </SBox>
    
  </STestimonial>)
}

export default Testimonial