import React from 'react'
import tw from "tailwind.macro"
import styled from 'styled-components'

const Typography = ({variant, tag, className, subText, children}) => {
  let STag = null;
  if (tag === 'h1') {
    const Sh1 = styled[tag]`
      ${tw`
        font-bold mb-5
      `}
      font-size: 3.5rem;
      letter-spacing: -0.8px;
      > p {
        ${tw`mt-4`}
        letter-spacing: 0;
        font-weight: normal;
      }
    `;
    return (<Sh1 className={className}>{children}</Sh1>)
  }
  else if (tag === 'h2') {
    const Sh2 = styled[tag]`
      ${tw`
        text-4xl font-bold pb-6
      `}
      & ${STag} {
        font-weight: 700;
        > p { font-weight: normal;}
      }
    `;
    return (<Sh2 className={className}>{children}</Sh2>)
  }
  else if (tag === 'h3') {
    const Sh3 = styled[tag]`
      ${tw`
        text-2xl mb-4
      `}
    `;
    return (<Sh3 className={className}>{children}</Sh3>)
  }
  else if (tag === 'p') {
    switch(variant) {
      case 'xlarge':
        STag  = styled[tag]`
          ${tw`text-xl leading-relaxed`}
        `
        break
      case 'large':
          STag  = styled[tag]`
            ${tw`text-lg`}
          `
          break
      default:
        STag  = styled[tag]`
          ${tw`text-base`}
        `
    }

    return (<STag className={className}>{children}</STag>)
  }
  // By default use p tag
  STag  = styled.p`
    ${tw`text-base`}
  `
  return (<p className={className}>{children}</p>)

}

export default Typography