import React from 'react'
import styled from 'styled-components'
import tw from "tailwind.macro"
import {theme} from '../../../../tailwind.config'

export const SRow = styled.div`
  ${tw`pt-24 pb-24`}
`

const Row = (props) => {
  const {children} = props
  const SRowExt = styled(SRow)`
    @media(max-width: ${theme.screens.md.max}) {
      ${(props.inverseMobile) && 'flex-direction: column-reverse;'}
    }
  `
  return (<SRowExt className="row" {...props}>{children}</SRowExt>)
}

export default Row