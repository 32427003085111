export const faqs = [
  {
      id: 'why_webhost',
      title: 'Why should I use Simplebackups when my webhost offers a backup solution?',
      content: `Webhost backup solutions vary a lot, while some may be good, others are not.<br/><br/>
        One general rule around safety is not to put all your eggs in the same basket, and relying on your webhost backup is 
        breaking this rule.<br/><br/>
        Although general webhost disaster is rare, human errors are not, and it is a good practice to host your backup somewhere else.
      `,
  },
  {
    id: 'cost_agency',
    title: 'How much does SimpleBackups costs for an agency?',
    content: `We offer at least 100 backups for for all our paid plans, the difference in plans resides in some automation features as well as dedicated support you will get in the higher plans.<br/>
      Our plans start at $29/month which should cover all of your backup needs. Knowing you can charge this service to your customers, it is needless to say it will be amortized right away.
    `,
  },
  {
    id: 'who_holds',
    title: 'Who holds my backups?',
    content: `You own your backups! Your backups are dumped on your own server, then securely transmitted from your server to your own cloud storage. <br/><br>
      The dumps are not stored on any third-party or even our servers during the backup process or transmission.    
    `,
  },
  {
    id: 'do_why',
    title: 'Why should I use Simplebackups when DigitalOcean offers a backup solution?',
    content: `Backups offered by DigitalOcean can only be configured to take a single snapshot of your server on a weekly basis.<br/>
    For many, this is not an acceptable solution as it means you might lost up to 7 days of data.<br/>
    On top of that taking a snapshot of your server is not a practical backup solution as you would have to restore your entire server
    image if you need to recover some data.
    `,
  },
  {
    id: 'how_much_cost',
    title: 'How much does SimpleBackups cost?',
    content: `We offer 4 different plans (from $0/m to $199/m), each with a set of included storage and features. You do not pay per backup, you pay a fixed monthly price for the backup service.
    `,
  },
  {
    id: 'do_how_much_cost_backups',
    title: 'How much does DigitalOcean charge for backups?',
    content: `DigitalOcean charges as much as 20% of your server monthly cost for weekly server backups, we simply think it is expensive and at the same time not enough.
    `,
  },
  {
    id: 'do_regions',
    title: 'What DigitalOcean regions are supported?',
    content: `We support all <a href="/resources/digitalocean-supported-regions/">DigitalOcean datacenter regions</a>. 
    `,
  },
  {
    id: 'how_long_create',
    title: 'How long does it take to schedule a backup?',
    content: `Assuming you have your server and database credentials available,
    we have seen it take less than 2 minutes and 14 seconds to install
    SimpleBackups and run the first backup! Hey! Think you can beat
    this time record? Let us know and we will give you a discount
    coupon :)
    `,
  },
  {
    id: 'is_secure',
    title: 'Is SimpleBackups secure?',
    content: `Absolutely! We take security very seriously due to the nature of
    what we do and encrypt all your sensitive information using
    industry-tested standards.
    <br />
    We even inform you about which fields are encrypted in our
    database when you create a server, a backup or a storage.
    <br />
    These encrypted fields are only decrypted when you login to your
    account or on-the-fly at runtime when SimpleBackups takes a backup
    for you.
    `,
  },
  {
    id: 'backups_crucial',
    title: 'Backups are crucial!',
    content: `Don't risk losing your website or database and let us help you do
    the job. Minimize the effects of a disaster so that your business
    or website can continue to operate or quickly revert back to an
    earlier version of its data.
    `,
  },
  {
    id: 'backups_complex',
    title: 'Backups are not too complicated!',
    content: `Indeed, they were. That's what we're solving with SimpleBackups.
    We help you configure backups in under 3 minutes, then nothing
    else is needed from your side.
    <br />
    <i>
      3 minutes, it's 3 times less than what it takes you to drink a
      cup of coffee.
    </i>
    `,
  },
  {
    id: 'start_free',
    title: 'You can start for free!',
    content: `Still not convinced, not sure if it's worth the money?
    SimpleBackups has a free trial, as well as a free plan, just give
    it a try, we do not even ask for your credit card information or
    details about you when you sign up. Just an email and a password
    to login.
    `,
  },
  {
    id: 'what_os_supported',
    title: 'What operating systems are supported?',
    content: `We support all operating system as long as it can execute bash commands: CentOS, Debian, Red Hat, Ubuntu, ...
    `,
  },
  {
    id: 'how_does_it_work',
    title: 'How does it work?',
    content: `You configure the parameters of your servers, define what storage you want to use and schedule your backups. 
      SimpleBackups then take care of getting your backup ran from your server to your select storage directly and update
      you in real time on your backup status.
    `,
  },
  {
    id: 'what_is_retention',
    title: 'What does "backup retention" mean?',
    content: `The "retention" of your backup is the number of backups we keep in your storage before rotating it.
      Ex: If you have a backup rotation set to 2 for a backup configured to run once a week it means you'll always
      keep the last 2 backups, after the third week the first week' backup will be deleted (aka rotated).
    `,
  },
  {
    id: 'what_gets_installed',
    title: 'What gets installed on my server?',
    content: `
      Tbd
    `,
  },
  {
    id: 'what_is_simplestorage',
    title: 'What is "SimpleStorage"?',
    content: `SimpleStorage is a backup storage we offer to our users for which you do not have to pay any additional cost.<br/>
      This backups is owned by us and a secure, shared fraction of it is dedicated to your backups.<br/>
      At any time, you can upgrade your account to get more SimpleStorage or switch to your own storage solution if you prefer to keep everything under control.
    `,
  },
  {
    id: 'use_for_client',
    title: 'Can I use SimpleBackups for client projects?',
    content: `Of course you can!<br/>
      Most of our customers leverage SimpleBackups to manage all their customers backups in one central place.<br/>
    `,
  },
  {
    id: 'refund_policty',
    title: 'What is your refund policy?',
    content: `If you are unhappy with your purchase for any reason, email us at support@simplebackups.com within 90 days and we'll refund you in full, no questions asked.<br/>
    `,
  },
  {
    id: 'why_use_sbio',
    title: 'Why using SimpleBackups?',
    content: `<ul><li>- Manage all your backups in once place</li><li>- One single command to get started</li><li>- Store your backups on your preferred storage</li></ul>
    `,
  },

]
